@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.WhoWe {
  padding: 9.375rem 0;
  @include responsive("mobile") {
    padding: 3.125rem 0;
  }
  h1 {
    font-size: 1.5rem;
    font-weight: 900;
    margin-bottom: 3.125rem;
    text-transform: uppercase;
    color: $green;
    text-align: center;
    @include responsive("mobile") {
      font-size: 1.125rem;
      margin-bottom: 1.875rem;
    }
  }
  .text {
    font-size: 1.125rem;
    color: $black;
    line-height: 2.25rem;
    max-width: 69.375rem;
    margin-left: auto;
    margin-right: auto;
    strong {
      font-weight: 700;
    }
    @include responsive("mobile") {
      font-size: 0.875rem;
      line-height: 1.5625rem;
      max-width: 100%;
    }
  }
}

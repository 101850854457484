@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.banner {
  .carousel-container-home {
    display: block;
    @include responsive("mobile") {
      display: none;
    }
  }
  .carousel-container-home-mobile {
    display: none;
    @include responsive("mobile") {
      display: block;
    }
  }

  .imageBanner {
    width: 100vw;
    height: 27.5rem;
    background-size: cover !important;
    @include responsive("mobile") {
      height: 15.625rem;
    }
  }
  .carousel-container-home,
  .carousel-container-home-mobile {
    .react-multi-carousel-dot-list {
      bottom: 0;
      margin-bottom: 1.25rem;
      li {
        button {
          border: 0;
          background-color: $white;
        }
        &.react-multi-carousel-dot--active {
          button {
            background-color: $green;
          }
        }
      }
    }
  }
}

.looseFurniture {
  padding: 6.25rem 0;
  @include responsive("mobile") {
    padding: 3.125rem 0;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 900;
    color: $green;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 3.125rem;
    @include responsive("mobile") {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }
  .contentFurnitureItem {
    display: flex;
    justify-content: flex-end;
  }
  .itemFurniture {
    max-width: 9.75rem;
    .iconContent {
      width: 9.75rem;
      height: 10.0625rem;
      background: $lightGray;
      border-radius: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .itemLabel {
      font-size: 1.125rem;
      color: $green;
      text-align: center;
      margin-top: 1.875rem;
    }
  }
}

#MonthOfferHome {
  background-color: $lightGray;
  padding: 6.25rem 0;
  @include responsive("mobile") {
    padding: 3.125rem 0;
  }
  .contentTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3.125rem;
    @include responsive("mobile") {
      display: none;
    }
    .title {
      font-size: 1.5rem;
      font-weight: 900;
      color: $black;
      margin-bottom: 0.625rem;
      text-transform: uppercase;
    }
    .subtitle {
      font-size: 1rem;
      color: $black;
    }
    .button {
      width: 17.625rem;
    }
  }
  .contentOffer,
  .contentOfferMobile {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    align-items: stretch;

    .item {
      width: 100%;
      margin: 0;
    }
  }
  .contentOffer {
    @include responsive("mobile") {
      display: none;
    }
  }
  .contentOfferMobile {
    display: none;
    @include responsive("mobile") {
      display: block;
    }
  }

  .MoreProductsMobile {
    display: none;
    @include responsive("mobile") {
      display: block;
      margin-top: 3.125rem;
      text-align: center;
    }
  }
}

.lineFurniture {
  padding: 6.25rem 0;
  @include responsive("mobile") {
    padding: 3.125rem 0;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 900;
    color: $green;
    text-align: center;
    margin-bottom: 1.875rem;
    text-transform: uppercase;
    @include responsive("mobile") {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }
  .contentImages {
    display: flex;
    flex-direction: row;
    @include responsive("mobile") {
      flex-direction: column;
    }
    .one {
      width: 50%;
      background-size: cover !important;
      background: $lightGray;
      @include responsive("mobile") {
        width: 100%;
        height: 15.625rem;
        margin-bottom: 1.875rem;
      }
    }
    .block {
      padding-left: 1.875rem;
      width: 50%;
      @include responsive("mobile") {
        padding-left: 0;
        width: 100%;
      }
      .two {
        height: 15.625rem;
        width: 100%;
        background: #0f0;
        margin-bottom: 1.875rem;
        background-size: cover !important;
        background: $lightGray;

        &:last-child {
          margin-bottom: 0;
        }
        @include responsive("mobile") {
          width: 100%;
          height: 15.625rem;
        }
      }
    }
  }
}

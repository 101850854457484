@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.item {
  background: $white;
  padding: 1.875rem;
  border-radius: 0.625rem;
  border: 1px solid #d9d9d9;
  width: 100%;
  margin: 0 0.9375rem;

  -webkit-box-shadow: 0 8px 10px 3px #e3dede;
  -moz-box-shadow: 0 8px 10px 3px #e3dede;
  box-shadow: 0 8px 10px 3px #e3dede;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  @include responsive("mobile") {
    padding: 0.9375rem;
  }
  .contentImage {
    height: 11.3125rem;
    border-radius: 0.625rem;
    margin-bottom: 1.875rem;
    background-size: cover !important;
    background: $lightGray;
    @include responsive("mobile") {
      height: 6.25rem;
    }
  }
  .title {
    font-size: 1rem;
    color: $black;
    margin-bottom: 1.875rem;
    @include responsive("mobile") {
      font-size: 0.875rem;
      margin-bottom: 0.9375rem;
    }
  }
  .price {
    font-size: 0.75rem;
    color: $lightGray2;
    margin-bottom: 0.3125rem;
  }
  .newPrice {
    font-size: 0.75rem;
    color: $black;
    strong {
      font-size: 1rem;
    }
  }
  a {
    cursor: pointer;
    margin: 0 auto;
  }
  .button {
    width: 100%;
    margin-top: 1.875rem;
    @include responsive("mobile") {
      margin-top: 0.9375rem;
    }
  }
  .hideImages {
    display: none;
  }
}

@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.TermsAndConditions {
  padding: 6.25rem 0;
  @include responsive("mobile") {
    padding: 3.125rem 0;
  }

  .box {
    background: $lightGray;
    padding: 1.875rem 3.125rem;
    border-radius: 0.625rem;
    @include responsive("mobile") {
      padding: 1.875rem;
    }
  }
  .title {
    font-size: 1.5rem;
    font-weight: 900;

    text-transform: uppercase;
    color: $green;
    margin-top: 1.875rem;
    margin-bottom: 0.3125rem;
    &:first-child {
      margin-top: 0;
    }
    @include responsive("mobile") {
      font-size: 1.125rem;
      margin-bottom: 1.875rem;
    }
  }
  .text {
    font-size: 1.125rem;
    color: $black;
    line-height: 2.25rem;
    margin-left: auto;
    margin-right: auto;
    strong {
      font-weight: 700;
    }
    @include responsive("mobile") {
      font-size: 0.875rem;
      line-height: 1.5625rem;
    }
  }
}

@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.buttonSocial {
  width: 2.875rem;
  height: 2.875rem;
  overflow: hidden;
  border-radius: 100%;
  margin-left: 0.9375rem;

  @include responsive("mobile") {
    width: 2.0625rem;
    height: 2.0625rem;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  a {
    margin-left: 0.625rem;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
  }
}

@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

#newsletter {
  background: $lightGray;
  padding: 6.25rem 0;
  @include responsive("mobile") {
    padding: 3.125rem 0;
  }
  .contentNewsletter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include responsive("mobile") {
      flex-direction: column;
    }
    .title {
      font-size: 1.5rem;
      font-weight: 900;
      color: $green;
      text-transform: uppercase;
      @include responsive("mobile") {
        font-size: 1.125rem;
        text-align: center;
      }
    }
    .contentInput {
      display: flex;
      flex-direction: row;
      @include responsive("mobile") {
        flex-direction: column;
        width: 100%;
        position: relative;
      }
      .button {
        margin-left: 50px;
        background: $black;
        @include responsive("mobile") {
          margin-left: 0;
          margin-top: 0.9375rem;
          width: auto;
        }
      }
    }
    .subtitle {
      font-size: 1rem;
      color: $green;
      margin-bottom: 1.875rem;
      margin-top: 1.875rem;
      @include responsive("mobile") {
        font-size: 0.75rem;
        margin-bottom: 0.9375rem;
        margin-top: 0.625rem;
        text-align: center;
      }
    }
    input {
      width: 23.75rem;
      padding: 0.75rem 0.625rem;
      background: $white;
      border: 1px solid #d9d9d9;
      border-radius: 0.625rem;
      @include responsive("mobile") {
        width: auto;
      }
    }
  }
}

@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

#MonthOffer {
  padding: 6.25rem 0 0;
  @include responsive("mobile") {
    padding: 3.125rem 0;
  }
  h1 {
    font-size: 4rem;
    text-transform: uppercase;
    color: $green;
    text-align: center;
    font-weight: 900;
    margin-bottom: 6.25rem;
    @include responsive("mobile") {
      font-size: 1.875rem;
      margin-bottom: 3.125rem;
    }
  }
  .contentOffer {
    flex-direction: row;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    @include responsive("mobile") {
      grid-template-columns: 1fr 1fr;
      grid-gap: 15px;
    }

    .item {
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      @include responsive("mobile") {
        margin: 0;
      }
    }
  }
  .BuyWhatsApp {
    width: 33.75rem;
    height: 7rem;
    margin: 50px auto;
    svg {
      width: 100% !important;
      height: 100% !important;
    }
    @include responsive("mobile") {
      width: 100%;
      height: 3.125rem;
      margin: 25px auto;
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3.125rem;
    @include responsive("mobile") {
      margin-top: 1.875rem;
    }
    p {
      font-size: 1.125rem;
      color: $black;
      margin: 0 1.25rem;
      @include responsive("mobile") {
        font-size: 0.875rem;
        margin: 0 0.625rem;
      }
    }

    button {
      border: none;
      background: none;
      cursor: pointer;

      &:first-child {
        transform: rotate(-180deg);
      }
      svg {
        path {
          fill: $green;
        }
      }
    }
  }
}
